import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { renameWorkspace } from '../../../store/workspaces/workspaces/actions';
import './styles.css';
import { WorkspaceInputStyled } from './styles';
import { updatePinnedWorkspaceAPI } from '../../../api/services/workspaces/updateWorkspacePinned';

const WorkspaceTabTextbox = ({ workspace }) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!workspace.isActive) {
      setIsEditing(false);
    }
  }, [workspace.isActive]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select(); 
    }
  }, [isEditing]);

  const toggleEditing = () => {
    if (workspace.isActive) {
      setIsEditing(true);
    }
  };

  const handleDivBlur = () => {
    if (workspace.name.trim() === '') {
      dispatch(renameWorkspace('workspace'));
      updatePinnedWorkspaceAPI();
    }
    setIsEditing(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleDivBlur();
    }
  };

  return (
    <div onFocus={toggleEditing} onBlur={workspace.isActive ? handleDivBlur : null}>
      {isEditing && workspace.isActive ? (
        <WorkspaceInputStyled
          ref={inputRef}
          value={workspace.name}
          onKeyPress={handleKeyPress}
          onChange={(e) => {
            dispatch(renameWorkspace(e.target.value));
            updatePinnedWorkspaceAPI();
          }}
          className="workspace-button-input"
          style={{ width: isEditing ? `${(workspace.name.length + 4) * 7}px` : null }}
          
          inputlength={workspace.name.length}
          editing={isEditing}
        />
      ) : (
        <div onDoubleClick={toggleEditing}>{workspace.name}</div>
      )}
    </div>
  );
};

WorkspaceTabTextbox.propTypes = {
  activeWorkspaceIndex: PropTypes.number,
  workspace: PropTypes.object,
  index: PropTypes.number,
};

export default WorkspaceTabTextbox;
