export const setAggregationHeight = () => {

  const gridContentElement: any = document.querySelector('.e-gridcontent');
  const gridPagerElement: any = document.querySelector('.e-gridpager');
  const gridFooterElement: any = document.querySelector('.e-gridfooter');

  const footerHeight = gridFooterElement.offsetHeight;
  const pagerHeight = gridFooterElement.offsetHeight;


  if (gridContentElement && gridFooterElement) {
    gridContentElement.style.height = `calc(100% - ${footerHeight + 68 }px)`;
  }

  if (gridContentElement && gridFooterElement && gridPagerElement) {
    gridContentElement.style.height = `calc(100% - ${footerHeight + pagerHeight + 88}px)`;
  }

};