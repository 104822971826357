import styled from 'styled-components';

interface WorkspaceInputProps {
  editing: boolean;
  inputlength: any;
}

interface WorkspaceButtonProps {
  active: any;
}

interface WorkspaceContainerProps {
  active: any;
}

interface DynamicRowProps {
  rowheight: string;
}


export const WorkspaceInputStyled = styled.input<WorkspaceInputProps>`
    border: none;
    border-bottom: 2px solid rgb(181, 181, 181); 
    outline: none !important;
    width: ${(props) => (props.editing ? `${(props.inputlength + 4) * 7}px` : null)};
`;


export const WorkspaceButtonBarStyled = styled.div`
    display: flex;
    margin-top: 2px;
    border-bottom: solid 1px #DFDFDF;
`;

export const WorkspaceButtonContainerStyled = styled.div<WorkspaceButtonProps>`
    background-color: ${({ active }) => (active ? 'white' : '#F2F2F2')};
    border: solid 1px #DFDFDF;
    border-bottom: ${({ active }) => (active ? 'none' : 'solid 1px #DFDFDF')};
    margin: 0 0 -1px -1px;
    padding: 3px;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: ${({ active }) => (active ? 'white' : '#DFDFDF')};
    }

    .workspace-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 1rem;

      &.e-icons {
        padding: 4px;
        border-radius: 50%;
        font-size: 10px;

        &:hover {
          background-color: #C2C2C2;
        }
      }
    }
`;

export const WorkspaceContentContainerStyled = styled.div<WorkspaceContainerProps>`
    height: calc(100vh - 31px);
    display: ${({ active }) => (active ? null : 'none')};
`;

export const InnerColumnStyled = styled.div`
    height: 100%;
`;

export const DynamicRowStyled = styled.div<DynamicRowProps>`
    margin: 2px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    height:  ${props => props.rowheight};

    & Form {
      height: 100%;
    }
`;


